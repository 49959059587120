import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { FaNetworkWired } from 'react-icons/fa'
import { SiShopware } from 'react-icons/si'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import affiliateone from '../data/affiliateone.png'
import affiliatetwo from '../data/affiliatetwo.png'

export const AffiliatehelpOne = () => {
    const [name, setName] = useState('Affiliate commissions')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>How much can you earn with our affiliate program?</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p>Our current promotion involves a $150 reward program, wherein we provide $20 for a Savings account and users can earn a commission of $130 by using any of the following deals: Hodler (trading) deal valued at $300 and more, or Classic Loan amounting to $1990 or more (equivalent in cryptocurrencies).</p>
                <p>We also have a revenue share offer where we give 20% of the revenue that will be generated by the users when trading on our platform. There is no minimum deposit to trigger this commission. The total amount that can be earned per user is $400.</p>
                <p>For a comprehensive understanding of the offers, including terms and conditions, please refer to the offer description available after completing the sign-up process on the Affiliate Dashboard.</p>
            </div>
        </div>
    )
}

export const AffiliatehelpTwo = () => {
    const [name, setName] = useState('Affiliate manager contacts')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p>If you have any questions not covered in our Affiliate program FAQ, or you have issues with signing up to the Affiliate program, or you have any comments, concerns, or suggestions, please use Support tickets inside the platform or contact our representative directly via:</p>
                <ul className='list-disc px-6 py-2'>
                    <li>Email: <a href='mailto:affiliates@weodler.com' className='text-blue-500 underline'>affiliates@Optionmates.com</a></li>
                </ul>
                <p>Join the Optionmates Affiliates Program. <a href='/company/affiliates' className='font-bold text-blue-500 underline'>Become an Affiliate</a></p>
            </div>
        </div>
    )
}

export const AffiliatehelpThree = () => {
    const [name, setName] = useState('Affiliate account')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>The difference between Optionmates and Affiliate accounts</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p className='font-bold'>Do I need to create a separate account on the Optionmates affiliate program or can I access it from my Optionmates account? </p>
                <ul className='list-disc px-6 py-2'>
                    <li>Optionmates and the Optionmates affiliate platform are two separate platforms, meaning you have to create a separate account for each. You can use the same email for both accounts, but we strongly recommend using different passwords for security reasons.</li>
                </ul>
                <p className='font-bold'>Do I need to have an account with Optionmates to become an affiliate? </p>
                <ul className='list-disc px-6 py-2'>
                    <li>To receive commission payouts in your preferred wallet, it is necessary to have an account with us in order to verify your identity. Once the funds are deposited, you can either utilize them for trading purposes, generate more interest by using our savings account, or withdraw them to your preferred wallet or bank account. </li>
                </ul>
                <p>If you have any questions, feel free to use Support tickets inside the platform or contact our representative directly via <a href='mailto:affiliates@Optionmates.com' className='text-blue-500 underline'>affiliates@Optionmates.com</a>.</p>
            </div>
        </div>
    )
}

export const AffiliatehelpFour = () => {
    const [name, setName] = useState('Referral link')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>How to find a referral (affiliate) link</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p className='font-bold'>Where is my referral link?</p>
                <ul className='list-disc px-6 py-2'>
                    <li>According to our Affiliate platform terminologies, we do not have referral links, we have affiliate tracking links. Nevertheless, the principle remains the same - an affiliate shares his affiliate tracking link with the potential customers, they register with it and the affiliate gets rewarded.</li>
                </ul>
                <p className='font-bold'>To find your affiliate tracking link you should do the following:</p>
                <ol className='list-decimal px-6 py-2 flex flex-col gap-2'>
                    <li>Log into your account on the affiliate platform</li>
                    <li>Click on it, read the offer description. At the end of the description, you'll find your tracking link. Copy it and share with your audience, friends, etc, or place it into your traffic source.</li>
                    <img src={affiliateone} alt="affiliatelink" className='block w-[90%] mx-auto my-4' />
                </ol>
            </div>
        </div>
    )
}

export const AffiliatehelpFive = () => {
    const [name, setName] = useState('How to track performance')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>How to track your referral progress</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p>On the Statistics page, you can view summary statistics in various parts using different filters.</p>
                <p>The Statistics section currently has the following parts:</p>
                <ol className='list-decimal px-6 py-2'>
                    <li><strong>Daily Statistics</strong> - use it to view your summary statistics or to check summary statistics for specific Offers/Countries/Operating systems (OS)/Devices/Smartlinks &amp; Sub accounts in the most convenient for you way.</li>
                    <p>On the Daily Statistics page you can see:</p>
                    <ul className='list-disc px-6 py-2'>
                        <li><strong>Filters</strong> - the data can be filtered by specific</li>
                        <li><strong>Date</strong> -choose the needed timerange you'd like to depict the data for. It is possible to view detailed statistics by pressing on the date.</li>
                        <li><strong>Traffic</strong></li>
                    </ul>
                    <p><strong>Hosts</strong> - unique clicks. The uniqueness of a click is defined by three categories in Affise: browser, device, and timerange of 24 hours.</p>
                    <p>Hits - a number of all clicks.</p>
                    <ul className='list-disc px-6 py-2'>
                        <li><strong>Impressions</strong> - a number of tracked Impressions (not used at the moment).</li>
                        <li><strong>Conversions</strong> - the number of conversions in Approved, Declined, and Pending statuses.</li>
                        <li><strong>Finances</strong> - the sum of money that will be paid you for conversions, which have Approved, Declined, and Pending statuses.</li>
                    </ul>
                    <li><strong>Conversion Statistics</strong></li>
                    <p>In the 'Conversions' slice of Statistics, you'll be able to see detailed conversions and export conversion statistics. By default, you'll see all conversions that happened within the last 7 days. Change the date range if needed.</p>
                    <img src={affiliatetwo} alt="affiliatetwo" className='block w-[90%] mx-auto my-4' />
                    <p>On the Conversion Statistics page you can see:</p>
                    <ul className='list-disc px-6 py-2'>
                        <li><strong>Date</strong> - displays the date and time of the conversion (UTC time zone by default).</li>
                        <li><strong>Offer</strong> - displays the offer name to which the conversion belongs.</li>
                        <li><strong>Geography / IP</strong> - displays country, city, and the IPv4 address of the conversion.</li>
                        <li><strong>Device</strong> - displays the device name (desktop, mobile, etc.), OS name and version (Windows 10, Mac OS X, Android 10.0, iOS 14, etc.), and browser version (Chrome 80, Mobile Safari 14.0, Firefox 84.0, etc.) of the conversion.</li>
                        <li><strong>Status</strong> - displays the status of the conversion (Pending, Declined, Approved, etc.).</li>
                        <li><strong>Income</strong> - displays the payout for the conversion, in USD</li>
                        <li><strong>Name of Goal</strong> - displays the name of the goal</li>
                        <li><strong>ID</strong> - displays the unique click ID of the conversion</li>
                    </ul>
                    <p className='my-4'>Meaning of goals</p>
                    <ul>
                        <li><strong>Registration</strong> - a user has successfully registered on the platform</li>
                        <li><strong>KYC</strong> - a user has successfully passed the identity verification</li>
                        <li><strong>Activation-hodl</strong> - a user has opened a Hodler deal</li>
                        <li><strong>Activation-loan</strong> - a user has successfully taken a Loan</li>
                        <li><strong>Activation-savings</strong> - a user has received weekly combined earning equal or more than 1 USD on his savings</li>
                        <li><strong>Activation</strong> - general goal for Activation-hodl and Activation-loan. Always happens along with user's first opened Hodler or takes Loan</li>
                    </ul>
                    <p className='mt-4'>The events flow and the payouts for each event to generate the maximum of 150 USD payout is following:</p>
                    <p><strong>Registration</strong> - 0 USD</p>
                    <p>and</p>
                    <p><strong>KYC</strong> - 0 USD</p>
                    <p>and</p>
                    <p><strong>Activation-hodl</strong>  - 130 USD<sup>*</sup></p>
                    <p>or</p>
                    <p><strong>Activation-loan</strong>  - 130 USD<sup>*</sup></p>
                    <p>and</p>
                    <p><strong>Activation-savings</strong> - 20USD (visible after 7 days if the total interest rate profit was 1 USD or more)</p>
                    <p className='mt-4'><sup>*</sup>Only the first use of either of these products triggers the 130 USD payout</p>
                </ol>
            </div>
        </div>
    )
}

export const AffiliatehelpSix = () => {
    const [name, setName] = useState('Payment terms and conditions')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>How to get paid for invited users</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <ul className='list-disc px-6 py-2 flex flex-col gap-2'>
                    <li>Your commission payments are handled through your YouHodler wallet, with a minimum threshold of just $30 (according to the exchange rate at the date of payment). The payments are done monthly on Net5.</li>
                    <li>If your payment method is cryptocurrencies or SEPA bank wire, we will pay you any commissions earned monthly, provided that your account is currently greater than $200 (according to the exchange rate at the date of payment). </li>
                    <li>Accounts with a balance of less than $200 will roll over to the next month and will continue to roll over monthly until $200 is reached. </li>
                    <li>If your payment method is SWIFT bank wire, we will pay you any commissions earned monthly, provided that your account is currently greater than $1000 (in EURO according to the exchange rate at the date of payment). </li>
                    <li>Accounts with a balance of less than $1000 will roll over to the next month and will continue to roll over monthly until $1000 is reached.</li>
                    <li>All payments are made with the Net5 hold period, e.g. payout for January will be made within the 5 first days of February, the payout for February will be made within the 5 first days of March, and so on.</li>
                    <li>All payouts are processed manually. You don't need to request the payout. If your balance is greater than the payout threshold, at the beginning of the month you will be contacted by our representative to confirm the payment details and method. After the confirmation, you will be paid.</li>
                </ul>
                <p className='font-bold'>If I can refer a client that deposits a huge amount to Optionmates (e.g. more than $100k), what will be my payout?</p>
                <p>We work with our affiliates on a CPA-based model for trading activities, not on deposit amounts.</p>
                <p>So, regardless of the sum, the referred customer starts trading on Optionmates platform, the maximum payout for each activated customer is $150.</p>
                <p>We also have a revenue-sharing program in place, where we share 20% of the trading revenue with you. You can access this offer through your affiliate dashboard and use the tracking link provided.</p>
            </div>
        </div>
    )
}

export const AffiliatehelpSeven = () => {
    const [name, setName] = useState('How to setup affiliate payments to the Optionmates account')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>How to set up affiliate payments to the Optionmates account</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-3'>
                <ul className='list-disc px-6 py-2'>
                    <li>For all the affiliate payments to Optionmates wallets the minimum payment threshold is now $20</li>
                    <li>All the payouts to Optionmates wallets are now automatically initiated within the first 5 working days of the following month. Deposits go directly to your Optionmates wallet, no more manual confirmations.</li>
                    <li>To process payout you must create a Optionmates account and verify your identity. This takes less than 2 minutes and is required only once.</li>
                </ul>
                <p>Here you can find all the required manuals for Optionmates account creation. In our case the two main articles are the following:</p>
                <ul className='list-disc px-6 py-2'>
                    <a href='/help/acounthelp/accounthelpone' className='text-blue-500 underline'><li>How to sign up</li></a>
                    <a href='/help/acounthelp/accounthelpfive' className='text-blue-500 underline'><li>How to verify your identity</li></a>
                </ul>
                <ul className='list-disc px-6 py-2 flex flex-col gap-2'>
                    <li>If you already have a Optionmates account, you only have to add it to your Affiliate account (see the instruction below).</li>
                    <li>If you don't want to or can't receive your affiliate payouts to the Optionmates wallet you can continue receiving your payments as you used to (Bank Wire, PayPal, or crypto payout to the wallets not related to Optionmates). But new $20 payment threshold does not apply to such payments, payment thresholds remain as they used to be (see paragraph 3 of the T&C). Also due to the latest AML (Anti-Money Laundering) and KYC (Know Your Client) procedures, we'll ask you to verify your identity by sending us a copy of your ID (passport, ID card, driving license, or residence permit). Without receiving this information from you, we won't be able to process your payments.</li>
                </ul>
                <p>Here is the detailed instruction on how to add your personal Optionmates wallet to the Affiliate account.</p>
                <p><strong>Step 1.</strong> Log into your Optionmates Affiliate account and click on your Affiliate account profile email address in the upper-right corner, then on Settings.</p>
                <p><strong>Step 2.</strong> Click on the Payment System. </p>
                <p><strong>Step 3.</strong> Click on the Add Payment System button.</p>
                <p><strong>Step 4.</strong> A pop-up window will appear, click on the Payment system drop-down menu.</p>
                <p><strong>Step 5.</strong> Scroll down the appeared drop-down menu till the end.</p>
                <p><strong>Step 6.</strong> Select Personal Optionmates account as a payment method.</p>
                <p><strong>Step 7.</strong> Fill in your Optionmates account email address (the email address you signed up with here) and the desired coin/token you'd like to be paid in. We can pay you in any coin/token Optionmates platform supports. Click Add Payment System button.</p>
                <p className='mt-6'>Payout will be done automatically to the Optionmates wallet in USDT in 5 (five) working days of the following month and in accordance with the exchange rate at the date of payment. There are no limits for the commission payout to the Optionmates account in cryptocurrency, the minimum threshold is $20. Once you add this info, no further wallet and token confirmation are needed.</p>
                <p>If you want to make changes to the payout terms (change coin/token), such changes must be requested in writing 5 (five) days prior to the end of the active month.</p>
            </div>
        </div>
    )
}

export const AffiliatehelpEight = () => {
    const [name, setName] = useState('Pending conversion status')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>Why the status of all my conversions is “pending”?</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p>This is because the default status of all conversions is “pending”. At the beginning of each month, our team checks all the conversions occurring in the previous month and changes their status to “Approved” or “Declined”.</p>
                <p>If the conversion status was changed to “Approved”, then this conversion is considered as valid and you will be rewarded for it (if it is a rewardable conversion).</p>
                <p>If the conversion status was changed to “Declined”, it means that the conversion is considered as invalid and you won’t be rewarded for it (if it is a rewardable conversion). But before changing the status to “Declined” you’ll be contacted by our representative with the detailed explanation why this conversion is considered as invalid. Please be ready to answer some additional questions and/or provide additional information regarding the conversion.</p>
            </div>
        </div>
    )
}

export const AffiliatehelpNine = () => {
    const [name, setName] = useState('How to restore your password')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>What should I do if I forget my password</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p>You can restore your password in two ways:</p>
                <ol className='list-decimal px-6 py-2'>
                    <li>You can simply click on 'Reset Password' on the sign in page and follow the instructions;</li>
                    <li>Or you can reach our representative and recover the password with their help.</li>
                </ol>
                <p>Contacts</p>
                <ul className='list-disc px-6 py-2'>
                    <li>Email: <a href='mailto:affiliates@Optionmates.com' className='text-blue-500 underline'>affiliates@youhodler.com</a></li>
                </ul>
                <p>If you have issues with logging in to your account, please try to restore your password or contact our representative.</p>
            </div>
        </div>
    )
}

export const AffiliatehelpTen = () => {
    const [name, setName] = useState('How to refer Optionmates to other affiliates')
    const navigate = useNavigate()

    return (
        <div className='p-6'>
            <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
                <div className='flex items-center gap-4 text-white'>
                    <SiShopware color='white' size={35} />
                    <p className='uppercase font-semibold'>Optionmates</p>
                </div>
                <div className='mt-4'>
                    <p>Hello there</p>
                    <p>Get help from support right away after looking at the various articles.</p>
                </div>
            </div>
            <div>
                <p className='my-4'><span onClick={()=> navigate(-2)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> &gt; <span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>Affiliate program</span> <span className='text-gray-500'>&gt; {name}</span></p>
                <h2 className='text-2xl font-bold my-3'>{name}</h2>
                <p className='text-gray-400'>How to benefit and track the performance of the referred affiliates?</p>
                <p className='text-gray-400'>Updated over a week ago</p>
            </div>
            <div className='my-6 flex flex-col gap-2'>
                <p>You are always welcome to refer to the Optionmates affiliate program new affiliates. </p>
                <p>To find your referral link (do not confuse it with the affiliate tracking link) you should do the following:</p>
                <ol className='list-decimal px-6 py-2'>
                    <li>Log in to your account</li>
                    <li>Click on your email address in the upper right corner</li>
                    <li>A drop down menu will appear, click on the 'Profile'</li>
                    <li>Click on the 'Referral program'</li>
                    <li>There you'll find your referral link</li>
                    <li>Copy it and share with potential affiliates</li>
                </ol>
                <p>Optionmates pays you 5% from the payments of the referred affiliates, lifetime. To check all the successfully registered affiliates (as well as their IDs and registration date) you referred, go to 'Referrals'.</p>
                <p>To track the statistics of the referred affiliates go to 'Statistics' - “Referrals.”</p>
            </div>
        </div>
    )
}

const Affiliatehelp = () => {
    const [name, setName] = useState('Affiliate Program')
    const navigate = useNavigate()
  return (
    <div className='p-6'>
        <div className='bg-blue-500 p-6 -mx-6 -mt-6'>
            <div className='flex items-center gap-4 text-white'>
                <SiShopware color='white' size={35} />
                <p className='uppercase font-semibold'>Optionmates</p>
            </div>
            <div className='mt-4'>
                <p>Hello there</p>
                <p>Get help from support right away after looking at the various articles.</p>
            </div>
        </div>
        <div>
            <p className='my-4'><span onClick={()=> navigate(-1)} className='cursor-pointer text-black hover:text-gray-600'>All Collections</span> <span className='text-gray-500'>&gt; {name}</span></p>
            <FaNetworkWired size={40} color='#3b82f6' />
            <h2 className='text-2xl font-bold uppercase my-3'>{name}</h2>
            <p>Common questions &amp; answers</p>
            <p className='text-gray-500'>10 articles</p>
        </div>

        <div className='p-3 border border-gray-300 rounded-lg mt-6'>
            <Link to={`affiliatehelpone`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>Affiliate commissions</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelptwo`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>Affiliate manager contacts</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpthree`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>Affiliate account</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpfour`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>Referral link</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpfive`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>How to track the performance</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpsix`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>Payment terms and conditions</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpseven`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>How to set up affiliate payments to the Optionmates account</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpeight`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>Pending conversion status</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpnine`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>How to restore your password</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
            <Link to={`affiliatehelpten`}>
                <div className='flex items-center justify-between rounded-lg p-2 cursor-pointer bg-transparent hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600'>
                    <p>How to refer Optionmates to other affiliates</p>
                    <MdOutlineKeyboardArrowRight color='#3b82f6' />
                </div>
            </Link>
        </div>
        <div className='my-10'>
            <div className='flex items-center gap-3 text-gray-200 justify-center'>
                <SiShopware color='#e5e7eb' size={35} />
                <p className='uppercase font-semibold'>Optionmates</p>
            </div>
        </div>
    </div>
  )
}

export default Affiliatehelp