import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from './contexts/ContextProvider'


const ProtectedRoutes = () => {
    const auth2 = localStorage.getItem('auth')
    const {auth} = useStateContext()

  return auth || auth2 ? <Outlet /> : <Navigate to={`/signin`} />
}

export default ProtectedRoutes