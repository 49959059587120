import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ConfirmRegistration = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            axios.post('https://api.optionmates.com/confirm', { token })
                .then(res => {
                    alert("Registration confirmed!");
                    navigate('/signin');
                })
                .catch(err => alert("Invalid or expired token."));
        }
    }, [navigate]);

    return <p>Confirming registration, please wait...</p>;
};

export default ConfirmRegistration;
